.index-page{
    max-width:100vw;
    height: 100vh;
    overflow-x: hidden;
    .header-box{
        
        //max-width: 1600px;
        //margin: 0 auto; /* 新增加的样式，使其在更大屏幕上居中 */
        max-width: 100%;
        height: 5rem;
        //overflow: hidden;
        //margin-top: 2.5rem;
        background-color: #161d15;
        position: relative;
        .container{
            
            width: calc(100vw - 2rem);
            height: 5rem;
            overflow: hidden;
            display: flex;
            justify-content: start; /* 确保子元素从左侧开始排列 */
            position: relative;
            margin-left: 2rem; /* 或根据需要调整，为main-pic提供足够的空间 */

            @media (max-width: 768px) {
                margin-left: 1 rem;
            }
            .main-pic{
                width: 18.75rem; /* 或根据实际图片大小调整 */  //300px
                height: 3.641rem; /* 或根据实际图片大小调整 */   //58.26px
                margin-top: 0.875rem; /* 调整以垂直居中图片 */   //14px
                display: flex; /* 如果需要的话 */
                @media (max-width: 768px) { // 针对平板和手机的设置
                    width: 13rem; /* 或根据实际图片大小调整 */  //300px
                    height: 3rem; /* 或根据实际图片大小调整 */   //58.26px
                    
                }
            }
            

            
           
            .user{
                position:absolute;
                
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 1.125rem; /* 调整间距可能需要 */  //18px
                //position: absolute;
                margin-left: 50rem; /* 改为相对于父容器居中 */
                //transform: translateX(-50%); /* 新增，确保元素居中 */
                //width: auto; /* 改为auto以适应内容宽度 */
                top: calc(50% - 17px/2 - 0.5px);
                font-size: 1.125rem;

                @media (max-width: 768px) { // 针对平板和手机的设置
                    position: absolute;
                    gap: 1.2rem;
                    margin-left: 22rem;
                    
                    
                }

                
                .home{
                    width: 5.6rem;
                    height: 1.6rem;
                    background-color: #161d15;
                    border-style: hidden;
                    color: #FAFAFA;
                }
                .services{
                    /* Frame 3 */
    
                    width: 5.6rem;
                    height: 1.6rem;
                    border-style: hidden;
                    background-color: #161d15;
                    color: #FAFAFA;
                    
                    // &:hover{
                        
                    //     //border-style: solid;
                    //     border-radius: 20px;
                    //     //background: #278DC6;
                    //     //color: whitesmoke;
                    //     background: linear-gradient(to bottom right, #A4E4FD, #278DC6);
                    //     // box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.4),
                    //     //             inset 0px -4px 0px 0px rgba(0,0,0,0.2),
                    //     //             0px 0px 0px 4px rgba(255,255,255,0.2),
                    //     //             0px 0px 180px 0px #9917FF;
                    //     box-shadow: inset 0 0 60px whitesmoke,
                    //                 //inset 20px 0 80px #f0f,
                    //                 //inset -20px 0 80px #278DC6,
                    //                 //inset 20px 0 300px #f0f,
                    //                 //inset -20px 0 300px #278DC6,
                    //                 //0 0 50px #fff,
                    //                 //-10px 0 80px #f0f,
                    //                 10px 0 80px #278DC6;
                    //     transform: translateY(0px);
                        
                    // }
    
                }
                .industries{
                    /* Frame 4 */
    
                    width: 5.6rem;
                    height: 1.6rem;
                    background-color: #161d15;
                    border-style: hidden;
                    color: #FAFAFA;
    
                }
                .company{
                    /* Frame 4 */
    
                    width: 8rem;
                    height: 1.6rem;
                    background-color: #161d15;
                    border-style: hidden;
                    color: #FAFAFA;
    
                }
            }
            
        }

    }

    .banner{
        width: 100vw;
        height: 60rem;
        
        
        background-image: url('../images/backgroundPic.png');
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        position: relative;
        z-index: 0;
        .slogan{
            width: 31.25rem;     //500px
            height: 9.375rem;    //150px
            padding: 21.875rem 0 0 15.31rem;   //350px 0 0 245px
            @media (max-width: 768px) {
                padding: 30rem 0 0 8rem; 
            }

            p{
                font-size: 3.125rem;
                color: whitesmoke;
                font-weight: bold;
                z-index: 10;
            }
            h3{
                
                font-size: 1.25rem;
                color: whitesmoke;
                font-weight: bold;
            }
            .view{
                background-color: #AAB8AB;
                border-style: hidden;
            }
        }
    }

    // 
    
    

    
    
    
 
    
    
    
}