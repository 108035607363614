.content-box{
        width: 100vw;
        height: 58rem;
        background-color: #FAFAFA;
        
        @media (max-width: 768px) {
            height: 275rem;
        }
        
        .small{
            width: 100vw;
            height: 11.25rem;   //180px
            margin-top: 1.25rem;   //20px
            margin-left: 1.875rem;  //30px
            line-height: 1.75rem;   //30px
            margin-bottom: 2rem;
            @media (max-width:768px) {
                margin-left: 0;
                line-height: 1rem;
                
            }
            
            .lineone{
                
                padding: 1.25rem 13.125rem;   //20px 210px
                color: green;
                
                @media (max-width: 768px) {
                    padding: 1.25rem 0 2.2rem 8rem; 
                    font-size: 1.25rem;
                }
            }
            .linetwo{
                
                font-size: 2.19rem;  //35px
                font-weight: bold;
                padding: 0 0 0.9375rem 13.125rem;   //0px 0 15px 210px

                @media (max-width: 768px) {
                    padding: 1.25rem 0 0 8rem; 
                    
                }
            }
            .linethree{
                padding: 0 0 0 13.125rem;   //0px 0 0px 210px
                @media (max-width: 768px) {
                    padding: 1.25rem 0 0 8rem; 
                    
                }
            }
            .linefour{
                padding: 0 0 0 13.125rem;
                @media (max-width: 768px) {
                    padding: 1.25rem 0 0 8rem; 
                    
                }
            }
            
        }
        .cards{
            
            display: flex;
            flex-wrap: wrap;
            width: 62.5rem;  //1000px
            margin-left: 12.5rem;  //200px
            margin-bottom: 1.875rem;   //30px
            justify-content: space-around;
            align-items: center;
           
           @media (max-width: 768px) {
            
            width: 100vw;
            margin-left: 0rem;
            height: 130rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 1.2rem
            
            
        }
            
            
        }
        .cards2{
            
            display: flex;
            flex-wrap: wrap;
            width: 62.5rem;  //1000px
            margin-left: 12.5rem;  //200px
            justify-content: space-around;
            align-items: center;
            @media (max-width: 768px) {
                
                width: 100vw;
                margin-left: 0rem;
                height: 130rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 1.2rem;
            }
        }

    }