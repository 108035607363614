.about-box{
    width: 100vw;
    height: 45rem;
    display: flex;
    position: relative;
    margin-top: 3.13rem; //50px
    .text{
        width: 50vw;
        height: 45rem;
        background-color: whitesmoke;
        
        
        .aboutus{
            padding: 1.25rem 0 0 13.13rem;  //20px 0 0 210px
            margin-left: 1.875rem; //30rem
            color: green;
            @media (max-width:768px) {
                font-size: 1.25rem;
                padding: 3rem 0 0 1rem;
            }
            
        }
        .line{
            font-size: 1.875rem;
            width: 18.75rem;
            font-weight: bold;
            padding: 3.75rem 0 0 14.68rem;  //60px 0 0 235px
            @media (max-width:768px) {
               
                padding: 3.75rem 0 0 3rem;
            }
        }
        .textcontent{
            
            padding: 1.875rem 0 0 14.68rem;  //30px 0 0 235px
            width: 28.125rem;  //450px
            height: 18.75rem;
           
            @media (max-width:768px) {
                padding: 1.875rem 0 0 3rem;
                height: 22rem;
                width: 24rem;
           
                justify-content: center;
                align-items: center;
            }
            
            .paragraph{
                font-size: 1.25rem;
                line-height: 1.75rem;
                
            }
        }
        .getintouch{
            padding: 0.625rem 0 0 14.68rem;   //10px 0 0 235px

            @media (max-width:768px) {
                padding: 0 0 0 3rem;
            }
        }

    }
    .pic{
        width: 50vw;
        height: 45rem;
        background-color: whitesmoke;
        .picimg{
            width: 28rem;
            height: 33rem;
            padding: 3.125rem 0 0 0; //50px
            @media (max-width:768px) {
                width: 25rem;
                height: 30rem;
                padding: 3.125rem 0 0 1rem;
            }
        }
        
    }
}
